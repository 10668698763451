import React, { useState, useReducer } from 'react';
import Container from '../components/container';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { navigate, Link } from 'gatsby'
import './login.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';

function requestReducer(state, action) {
  switch (action.type) {
    case 'send':
      return {isLoading: true, status: null}
    case 'success':
      return {isLoading: false, status: 'success'}
    case 'error':
      return {isLoading: false, status: 'error'}
    case 'error-mismatch':
      return {isLoading: false, status: 'error-mismatch'}
  }
}

const ResetPassword = (props) => {
  const resetCode = props.location.search.split('=')[1];
  const [pass1Input, setPass1Input] = useState('');
  const [pass2Input, setPass2Input] = useState('');
  const [request, dispatchRequest] = useReducer(requestReducer, {
    isLoading: false,
    status: null
  });


  const handleRequest = async (event) => {
    dispatchRequest({type: 'send'});
    event.preventDefault();

    if(pass1Input !== pass2Input) {
      dispatchRequest({type: 'error-mismatch'});
      return;
    }

    try {
      const {data} = await axios({
        method: 'POST',
        url: `${process.env.GATSBY_BACKEND_SERVER_URL}/auth/reset-password`,
        data: {
          code: resetCode,
          password: pass1Input,
          passwordConfirmation: pass2Input
        }
      });
      dispatchRequest({type: 'success'});
      setPass1Input('');
      setPass2Input('');
    } catch (e) {
      dispatchRequest({type: 'error'});
    }
  }

  return (
    <Layout>
      <SEO title="Passwort erstellen"/>
      <Container>
        <h1>Passwort erstellen</h1>
        <p style={{textAlign: 'center'}}>
          Ihre E-Mail Adresse wurde erfolgreich bestätigt. Bitte erstellen Sie Ihr persönliches Passwort.
        </p>
        <form className="form" onSubmit={handleRequest}>
          <div className="form__form-group">
            {/*<label htmlFor="email">E-Mail Adresse</label>*/}
            <input
              type="password"
              id="password-1"
              name="password-1"
              required={true}
              placeholder="Ihr Passwort "
              value={pass1Input}
              onChange={(event) => setPass1Input(event.target.value)}
            />
          </div>
          <div className="form__form-group">
            {/*<label htmlFor="email">E-Mail Adresse</label>*/}
            <input
              type="password"
              id="password-2"
              name="password-2"
              required={true}
              placeholder="Passwort erneut eingeben"
              value={pass2Input}
              onChange={(event) => setPass2Input(event.target.value)}
            />
          </div>
          <div className="form__form-group">
            <button className="button button_size_large-1" type="submit">
              {request.isLoading ? (
                <FontAwesomeIcon className="" icon={faSpinner} spin/>
              ) : (
                "Passwort bestätigen"
              )}
            </button>
          </div>
          <span className={`form__status-text ${(request.status === 'error' || request.status === 'error-mismatch') && 'form__status-text_error'} ${request.status === 'success' && 'form__status-text_success'}`}>
            {request.status === 'error' && "Anfrage fehlgeschlagen"}
            {request.status === 'success' && (
              <div>
                Ihr Passwort wurde erfolgreich erstellt. Sie können sich nun im <Link to="/intern/login">Login</Link> anmelden.
              </div>
            )}
            {request.status === 'error-mismatch' && "Die Eingaben sind nicht identisch"}
          </span>
        </form>
      </Container>
    </Layout>
  );
};

export default ResetPassword;
